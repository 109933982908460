<template>
  <!--begin::Filter-->
  <v-navigation-drawer
    fixed
    temporary
    right
    hide-overlay
    style="z-index: 99;"
    v-model="showFilterDialog"
    width="375"
  >
    <v-list-item class="px-6">
      <v-list-item-content class="pt-5 pb-1">
        <v-list-item-title><h4>Filters</h4></v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-layout wrap class="px-6">
      <v-flex xs12>
        <v-select
          v-model="filterForm.selectedEnvironment"
          :items="filterEnvironmentOptions"
          label="Environment"
          item-key="text"
          return-object
          persistent-hint
          outlined
          dense
          clearable
        >
        </v-select>
      </v-flex>

      <v-flex xs12>
        <datetime-interval-picker
          @dateFilterChanged="onDateRangeChange"
          :presets="presets"
          :use-custom-range="false"
        />
      </v-flex>

      <v-flex xs12 class="mt-6">
        <v-btn type="button" color="primary" @click="applyFilters">
          Apply
        </v-btn>
      </v-flex>
    </v-layout>
  </v-navigation-drawer>
  <!--end::Filter-->
</template>

<script>
import DatetimeIntervalPicker from "@/view/content/DatetimeIntervalPicker";

export default {
  name: "FilterDialog",
  components: {
    DatetimeIntervalPicker: DatetimeIntervalPicker
  },
  data() {
    return {
      showFilterDialog: false,
      filterForm: {},
      timespan: null,
      filterEnvironmentOptions: [
        {
          text: "Test",
          account_id: "426399108012",
          providerAccountName: "Sentia Group: Enterprise Development (Test)"
        },
        {
          text: "QA",
          account_id: "407673466635",
          providerAccountName: "Sentia Group: Enterprise Development (QA)"
        },
        {
          text: "Production",
          account_id: "566977587100",
          providerAccountName:
            "Sentia Group: Enterprise Development (Production)"
        }
      ],
      presets: [
        { label: "5 minutes", interval: 5, unit: "minute" },
        { label: "30 minutes", interval: 30, unit: "minute" },
        { label: "1 hour", interval: 1, unit: "hour" },
        { label: "4 hours", interval: 4, unit: "hour" },
        { label: "1 day", interval: 1, unit: "day" },
        { label: "7 days", interval: 7, unit: "day" },
        { label: "1 month", interval: 1, unit: "month" }
      ]
    };
  },
  mounted() {
    this.filterForm.selectedEnvironment = this.filterEnvironmentOptions
      .where(x => x.text.toLowerCase() === process.env.VUE_APP_NODE_ENV)
      .first();

    this.applyFilters();
  },
  methods: {
    showDialog() {
      this.showFilterDialog = true;
    },
    applyFilters() {
      this.showFilterDialog = false;
      this.$emit("applyFilters", this.filterForm);
    },
    onDateRangeChange(payload) {
      this.filterForm.startDate = payload.startDate;
      this.filterForm.endDate = payload.endDate;
      this.filterForm.dateTimeSelectionLabel = payload.dateTimeSelectionLabel;
    }
  }
};
</script>
