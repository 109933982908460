<template>
  <div>
    <div class="d-flex align-items-start justify-content-between flex-grow-1">
      <div class="mr-2">
        <h6 class="font-weight-bolder">
          {{ title }}
        </h6>
        <div class="text-muted font-size-lg mt-2">
          {{ dateTimeLabel }}
        </div>
      </div>
      <div class="spinner-border text-warning" role="status" v-if="isLoading">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!isLoading">
      <div class="pt-4" v-for="item in items" :key="item.name">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="font-weight-bold mr-2">
            {{ item.name }}
          </div>
          <div class="font-weight-bold">
            {{ item.countLabel }}
          </div>
        </div>
        <div class="progress bg-light-secondary progress-xs">
          <div
            role="progressbar"
            v-bind:style="{
              width: item.percentage + '%',
              'background-color': item.color + ' !important'
            }"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PerformanceGraph",
  props: {
    title: {
      type: String,
      default: "Chart"
    }
  },
  data: function () {
    return {
      isLoading: true,
      dateTimeLabel: "",
      items: []
    };
  },
  methods: {
    loadData(dateTimeLabel) {
      this.dateTimeLabel = dateTimeLabel;
      this.isLoading = true;
    },
    drawChart(data) {
      if (!data) {
        return;
      }

      let jsonData = JSON.parse(data);
      let colors = [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#33B2DF",
        "#546E7A",
        "#D4526E",
        "#13D8AA",
        "#A5978B"
      ];

      let convertedJsonData = [];
      if (jsonData.any()) {
        let highestValue = jsonData.max(x => x.count);
        jsonData.forEach(function (element, index) {
          if (element.count === highestValue) {
            element["percentage"] = 100;
          } else {
            element["percentage"] = (element["count"] * 100) / highestValue;
          }

          element["countLabel"] = (element["count"] / 1000).toFixed(2) + " k";
          element["color"] = colors[index % jsonData.length];
          convertedJsonData.push(element);
        });

        this.items = convertedJsonData;
      }

      this.isLoading = false;
    }
  }
};
</script>
