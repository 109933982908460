<template>
  <div>
    <div class="d-flex align-items-start justify-content-between flex-grow-1">
      <div class="mr-2">
        <h6 class="font-weight-bolder">
          {{ title }}
        </h6>
        <div class="text-muted font-size-lg mt-2">
          {{ dateTimeLabel }}
        </div>
      </div>
      <div class="spinner-border text-warning" role="status" v-if="isLoading">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <apexchart
      ref="demoChart"
      v-if="!isLoading"
      width="100%"
      height="500"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "PerformanceGraph",
  props: {
    title: {
      type: String,
      default: "Chart"
    },
    chartType: {
      type: String,
      default: "line"
    },
    yaxis: {
      type: Object,
      default() {
        return {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return Math.round(val);
            }
          }
        };
      }
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  data: function () {
    return {
      isLoading: true,
      dateTimeLabel: "",
      options: {
        chart: {
          id: "vuechart-performance",
          type: this.chartType,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: false,
          shared: false,
          marker: {
            show: true
          },
          x: {
            show: false
          }
        },
        legend: {
          horizontalAlign: "left"
        },
        stroke: {
          width: 2,
          curve: "straight"
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm"
            }
          }
        },
        yaxis: this.yaxis
      },
      series: []
    };
  },
  methods: {
    loadData(dateTimeLabel) {
      this.dateTimeLabel = dateTimeLabel;
      this.isLoading = true;
    },
    drawChart(data) {
      if (this.chartType === "line") {
        this.drawLineChart(data);
        this.isLoading = false;
      }
    },
    getChartData(data, name) {
      let newList = [];
      if (data) {
        data.forEach(element => {
          newList.push({
            x: new Date(element.beginTimeSeconds * 1000).toISOString(),
            y: element[name] ? Math.round(element[name] * 100) / 100 : 0
          });
        });
      }

      return newList;
    },
    drawLineChart(data) {
      let jsonData = JSON.parse(data);
      if (!jsonData.any()) {
        return;
      }

      let convertedJsonData = {};
      jsonData.forEach(element => {
        let facet = element.facet;
        if (Array.isArray(element.facet)) {
          facet = element.facet.join(", ");
        }

        if (!convertedJsonData[facet]) {
          convertedJsonData[facet] = [];
        }

        convertedJsonData[facet].push(element);
      });

      this.series = [];
      for (const item in convertedJsonData) {
        this.series.push({
          name: item,
          data: this.getChartData(convertedJsonData[item], "count")
        });
      }

      this.isLoading = false;
    }
  }
};
</script>
