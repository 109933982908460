import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import { NEWRELIC_NRQL } from "@/core/services/graphql/queries/new_relic/queries";
import PerformanceGraph from "@/view/content/PerformanceGraph";
import ProgressBarGraph from "@/view/content/ProgressBarGraph";
import PieGraph from "@/view/content/PieGraph";
import FilterDialog from "./components/FilterDialog";

export default {
  name: "TechnicalDashboard",
  components: {
    FilterDialog: FilterDialog,
    PerformanceGraph: PerformanceGraph,
    ProgressBarGraph: ProgressBarGraph,
    PieGraph: PieGraph
  },
  data() {
    return {
      newRelicAccountId: "2751202",
      logAccountId: "566977587100",
      account_id: null,
      providerAccountName: null,
      timespan: "Since 1 day ago",
      dateTimeSelectionLabel: "Since 1 day ago"
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Technical Dashboard" }]);
  },
  methods: {
    loadData() {
      this.loadLambdaErrors();
      this.loadLambdaHighestInvocations();
      this.loadLambdaWithMostErrors();
      this.loadLambdaAverageSlowest();
      this.loadMostActiveAppClients();
    },
    loadLambdaErrors() {
      this.$refs.lambdaErrorsGraph.loadData(this.dateTimeSelectionLabel);
      this.$apollo
        .query({
          query: NEWRELIC_NRQL,
          variables: {
            account_id: this.newRelicAccountId,
            query: `SELECT count(*) FROM AwsLambdaInvocationError FACET entityName WHERE providerAccountName = '${this.providerAccountName}' ${this.timespan} TIMESERIES EXTRAPOLATE`
          }
        })
        .then(response => {
          this.$refs.lambdaErrorsGraph.drawChart(
            response?.data?.platforms?.new_relic?.nrql?.result?.response
          );
        });
    },
    loadLambdaHighestInvocations() {
      this.$refs.highestLambdaGraph.loadData(this.dateTimeSelectionLabel);
      this.$apollo
        .query({
          query: NEWRELIC_NRQL,
          variables: {
            account_id: this.newRelicAccountId,
            query: `SELECT COUNT(*) FROM AwsLambdaInvocation FACET \`label.aws:cloudformation:logical-id\` AS 'name' WHERE providerAccountName = '${this.providerAccountName}' ${this.timespan}`
          }
        })
        .then(response => {
          this.$refs.highestLambdaGraph.drawChart(
            response?.data?.platforms?.new_relic?.nrql?.result.response
          );
        });
    },
    loadLambdaWithMostErrors() {
      this.$refs.lambdasWithMostErrorsGraph.loadData(
        this.dateTimeSelectionLabel
      );
      this.$apollo
        .query({
          query: NEWRELIC_NRQL,
          variables: {
            account_id: this.newRelicAccountId,
            query: `SELECT uniqueCount(conversation_id) as 'count' FROM Log FACET name WHERE levelname='ERROR' AND account_id ='${this.account_id}' ${this.timespan}`
          }
        })
        .then(response => {
          this.$refs.lambdasWithMostErrorsGraph.drawChart(
            response?.data?.platforms?.new_relic?.nrql?.result.response
          );
        });
    },
    loadLambdaAverageSlowest() {
      this.$refs.averageSlowestLambdaGraph.loadData(
        this.dateTimeSelectionLabel
      );
      this.$apollo
        .query({
          query: NEWRELIC_NRQL,
          variables: {
            account_id: this.newRelicAccountId,
            query: `SELECT average(cwBilledDuration) as 'count' FROM AwsLambdaInvocation FACET \`label.aws:cloudformation:logical-id\` AS 'name' WHERE providerAccountName = '${this.providerAccountName}' ${this.timespan}`
          }
        })
        .then(response => {
          this.$refs.averageSlowestLambdaGraph.drawChart(
            response?.data?.platforms?.new_relic?.nrql?.result.response
          );
        });
    },
    loadMostActiveAppClients() {
      this.$refs.mostActiveAppClientsGraph.loadData(
        this.dateTimeSelectionLabel
      );

      this.$apollo
        .query({
          query: NEWRELIC_NRQL,
          variables: {
            account_id: this.newRelicAccountId,
            query: `SELECT uniqueCount(conversation_id) AS 'count' 
            FROM Log 
            WHERE account_id ='${this.account_id}' ${this.timespan}             
            FACET cases(
              WHERE identity_client_id='null' and identity_username='null' AS 'API key', 
              WHERE identity_client_id='null' and (identity_username LIKE '%AssumeRoleSessionSala' OR identity_username LIKE '%Gql-API-Client-session') AS 'SALA internal', 
            WHERE identity_client_id = '12peognle4rhrkmitf87k77k3b' AS 'NL SIS test',
            WHERE identity_client_id = '15jo9etngsmh424109mr6ouho5' AS 'io servicenow monitoring qa',
            WHERE identity_client_id = '19uap53nu2ohc8kcmshk9mohup' AS ' NL Blue Endpoint administration',
            WHERE identity_client_id = '1g2hld2lcq26prtcu7fu743q9m' AS 'NL Julio Hegedus Ruby Script',
            WHERE identity_client_id = '1mmh6t01ahb38257gaupa3caon' AS 'UserPoolClientWeb-EAYQk8WdA2XD',
            WHERE identity_client_id = '1oenf6o448u99tanmrrdt9euok' AS 'Hungry hippo',
            WHERE identity_client_id = '1vj8qotmc69uao0qfqrc6t8l5v' AS 'NL Blue Backup Script',
            WHERE identity_client_id = '205gtf8doqq8dlveth6ebtcmf' AS 'sg-gi-networking-servicenow-query',
            WHERE identity_client_id = '21nni2k3432r8n3kgrbmklir8p' AS 'servicenow monitoring',
            WHERE identity_client_id = '26jqad3oi2le42v84n3vfsfml3' AS 'NL Amsio',
            WHERE identity_client_id = '29jisai4mkvb772ras4sknrunh' AS 'SG Sync Dynamics CE Account to SALA',
            WHERE identity_client_id = '2g9p4b4trpbmk90rvmb1hreaa9' AS 'NL PensioennavigatorUserAudit',
            WHERE identity_client_id = '2m6f049ss9n2fp21g4c7vulidv' AS 'NL Team Blue Zabix',
            WHERE identity_client_id = '2ofioq5hq8oi7p9rhc6ddg05nq' AS 'NL SCC ServiceNow Worklogs ETL',
            WHERE identity_client_id = '3492ns51lgp4lffvghhpk52o8d' AS 'SG Sync Dynamics CE Contact to SALA',
            WHERE identity_client_id = '3kttgbmsbn89c9ovofhc6ii55f' AS 'UserPoolClient-2PqiqYmlKWqU',
            WHERE identity_client_id = '3m2hojvg0ku4m91iefr0h456i9' AS 'NL - Team Purple Tickets',
            WHERE identity_client_id = '3ofpbu02lg0650bhcruk784r9a' AS 'NL Blue VEEAM backupcheck',
            WHERE identity_client_id = '42lns9epkvn12uot9m3pefqoun' AS 'NL Blue LUMC',
            WHERE identity_client_id = '48llp7hs3k505drsu5r83qulmh' AS 'NL Blue Toolkit',
            WHERE identity_client_id = '4gffmr5e3urc40c7mvqtfg1g7o' AS 'DK IPNordic External',
            WHERE identity_client_id = '4lat8ek4gi6gar8d1ninm7tbv9' AS 'NL Team Yellow Ansible',
            WHERE identity_client_id = '4ncac0ft6945gmjbtljl27r76t' AS 'GI Networking App',
            WHERE identity_client_id = '4pj4phniidqj9v9vne5413vu11' AS 'SG Dev NR Synthetics',
            WHERE identity_client_id = '4tnl32qthpj77gr1mk2bd6ovqg' AS 'NL SIS MB',
            WHERE identity_client_id = '5vhcrk5c3jvdrbq09uao0n928l' AS 'NL SCC FOX ids ETL',
            WHERE identity_client_id = '62hgkhs5livm53s0uf2e5o3obd' AS 'NL SIS production',
            WHERE identity_client_id = '643ekldttshrcgsg0j3fsr5tpn' AS 'NL Blue LE check',
            WHERE identity_client_id = '645sm7jblj0tufe9e2881jpfaf' AS 'NL - SCC CMDB ETL',
            WHERE identity_client_id = '65pvfi05a5ilbkbtbqhle5e9vd' AS 'NL Team Green Ansible',
            WHERE identity_client_id = '69sril23uovq817mtgg09pd8vs' AS 'NL Blue PKG',
            WHERE identity_client_id = '6co03bq8mihf58pct63i886qid' AS 'sg-gi-networking-netbox',
            WHERE identity_client_id = '6fl5gpjkk3htopclqk0odtqbk' AS 'Lets Encrypt Check',
            WHERE identity_client_id = '6h3k2opdn64j2550td8lkrugjs' AS 'SG D365 CE VAT VALIDATOR',
            WHERE identity_client_id = '6olveh5n3aeejhdfbmv4vatrsg' AS 'Camunda Production access',
            WHERE identity_client_id = '6shibii6s4g6j4o01kp3itnbiq' AS 'NL Blue endpoint administration',
            WHERE identity_client_id = '6t8jte0qvo43ib11an1trjha4j' AS 'network_cmdb_update',
            WHERE identity_client_id = '74bt5pt5erupb0ahr7a87b704b' AS 'UserPoolClient-Postman',
            WHERE identity_client_id = '7545c0n7u22phqjhapdhu3j1pd' AS 'DK DEV CSM',
            WHERE identity_client_id = '76uqqgii1g4ft6ksp7m0c2hvc0' AS 'NL SIS QA',
            WHERE identity_client_id = '77deuuo369nvocda9ece6q19lh' AS 'NL - SCC JIRA Worklogs ETL',
            WHERE identity_client_id = '7hun4cv4091n15gjepr30rb5ku' AS 'Cap Team Linux DK',
            WHERE identity_client_id = '7hvq2958fpdr9fg8tkbkgh5oh7' AS 'SG - TMS',
            WHERE identity_client_id = '7muhb1ketp37jhvmsjro7lhk16' AS 'Amsio',
            WHERE identity_client_id = '7o2ba0i8tdeknktr9s4e1jendc' AS 'io servicenow monitoring test',
            WHERE identity_client_id = '7t6fs74ed35qppi566v04hv39f' AS 'NL SCC Okta Users ETL',
            WHERE identity_client_id = '8fl9hgdhpo5quk5o2tbejpm0o' AS 'NL SCC ServiceNow Tickets ETL',
            WHERE identity_client_id = 'd5mul35p6b5023e0s0uj4uhrm' AS 'DK GI Network Config Generator',
            WHERE identity_client_id = 'li2tor26p2p687cou980bfbav' AS 'DK SCOM Incidents',
            WHERE identity_client_id = '578pck0tg6opr2sp6eos1ja9o1' AS 'UserPoolClient-2sHRiDuWxdjL',
            WHERE identity_client_id = '7p8rjdrqanm52ql1p7vevkvdvk' AS 'UserPoolClientBelgiumMyPortal-dRHBO7Y2eFVU',
            WHERE identity_client_id = 'e140ulj5lo10lmr9bk44p356o' AS 'UserPoolClientWeb-XaLXSrNhMlFe',
            WHERE identity_client_id = '108lfhanb9stc9aehlg2cm5919' AS 'NL Global Infra Networking QA',
            WHERE identity_client_id = '16ga3cljj555g7bmng9t98gsbp' AS 'UserPoolClientWeb-Nalr4FbtnAIr',
            WHERE identity_client_id = '2hagtelkeoagmaha995jukn4at' AS 'UserPoolClient-PieterLambrecht',
            WHERE identity_client_id = '2lb6rpb404lst9347p768asck6' AS 'network cmdb update qa',
            WHERE identity_client_id = '3e7298gn1eo1bsogvkf3c7aj7v' AS 'UserPoolClient-Postman',
            WHERE identity_client_id = '4blngdpbsgoie55il2v5799pad' AS 'NL Team Purple QA',
            WHERE identity_client_id = '4huiitobn1k8v02r8tdhmjhemn' AS 'SG Sync Dynamics CE Contact to SALA QA',
            WHERE identity_client_id = '4qfhp8vd2rah3nld5h642r66fr' AS 'NL Julio Hegedus Ruby Script QA',
            WHERE identity_client_id = '4tuj7ipseuq361gjnn1j71tutc' AS 'DK PMO Reporting QA',
            WHERE identity_client_id = '5iqck4cv8k2eq6uulhhkc7srn0' AS 'DK DEV CSA QA',
            WHERE identity_client_id = '5q152rkl9mhcsh34e225r7l02g' AS 'UserPoolClient-TeamJappe',
            WHERE identity_client_id = '6ge1veah648jikoh9l30697qro' AS 'NL SCC Okta Users ETL QA',
            WHERE identity_client_id = '6gsu74htjkoq0q1jo75v1i7i96' AS 'NL SIS QA',
            WHERE identity_client_id = '6hhhc1insfpf7gur5dpj01c467' AS 'NL Sadis QA',
            WHERE identity_client_id = '6m0e0b2i8bdvj9tk8rtl2ekncs' AS ' SG Sync Dynamics CE Account to SALA QA',
            WHERE identity_client_id = '6v30pqf53jku2sqpm1qlkvnhf7' AS 'UserPoolClient-dqMJ4wW3EB5V',
            WHERE identity_client_id = '7507uace06420ds318lsblkac2' AS 'NL AMSIO QA',
            WHERE identity_client_id = '7arpt99qj854vns5arni91lfcc' AS 'NL Team Yellow Ansible QA',
            WHERE identity_client_id = '7tpdq5cakg37f3fioe197b498t' AS 'SG D365 CE VAT VALIDATOR QA',
            WHERE identity_client_id = 'dkpeoh400mmqa1op2pssvekrg' AS 'UserPoolClient-ApiGateway',
            WHERE identity_client_id = 'eo0047u1mt0jtie1vsbsgkfbu' AS 'SG Dev NR Synthetics QA',
            WHERE identity_client_id = 's42mb9lk1921ocsiafieoebca' AS 'NL Team Green Ansible QA',
            WHERE identity_client_id = '5n7cplo9nebp1uq58u3qo7cqeh' AS 'UserPoolClientBelgiumMyPortal-ZZvMiL5cO8IK',
            WHERE identity_client_id = '60njtie4im8159nm0nijef3sep' AS 'UserPoolClient-DAJT1dQ0QySF',
            WHERE identity_client_id = '62l4k7ukl06961b6q0nd2ul552' AS 'UserPoolClientWeb-Wecf0SNdb5kt',
            WHERE identity_client_id = '3uuq7cadrd2q10if1bog5aq8hf' AS 'NL SIS Test',
            WHERE identity_client_id = '48aguiifdaumbho9406le063mp' AS 'UserPoolClient-IntegrationTests',
            WHERE identity_client_id = '5bm7dco9uqp8ildsfugf43knn6' AS 'UserPoolClient-Postman',
            WHERE identity_client_id = '5p1hi2g4s761epahcql3mgmc99' AS 'UserPoolClient-0kyRIvNLHi1F',
            WHERE identity_client_id = '5vmf7okfgh8ft0ptcdifs1di68' AS 'UserPoolClientWeb-WGoEHPaH9wNo',
            WHERE identity_client_id = '43lcv6v3b02ojnhs1uuohsmj1m' AS 'Integration-Tests',
            WHERE identity_client_id = '54usn6h5psfi4pqpj8qu0h2k95' AS 'UserPoolClientWeb-xs95W5Y4WOnN',
            WHERE identity_client_id = '6vc9h5o9hdatc308cnn0avcq78' AS 'UserPoolClient-4Vvo1czYNaKE',
            WHERE identity_client_id = 'tu110n3bhg5cg4k4o7rr2p36s' AS 'UserPoolClientBelgiumMyPortal-Qh7nwMhgOydb'
            ) OR identity_username LIMIT 50`.replace(/\n/g, "")
          }
        })
        .then(response => {
          this.$refs.mostActiveAppClientsGraph.drawChart(
            response?.data?.platforms?.new_relic?.nrql?.result?.response
          );
        });
    },
    showFilterDialog() {
      this.$refs.filterDialog.showDialog();
    },
    applyFilters(filters) {
      this.filters = filters ?? {};

      if (this.filters.selectedEnvironment) {
        this.account_id = this.filters.selectedEnvironment.account_id;
        this.providerAccountName = this.filters.selectedEnvironment.providerAccountName;
      }

      if (this.filters.startDate && this.filters.endDate) {
        this.timespan = `SINCE '${this.filters.startDate}' UNTIL '${this.filters.endDate}'`;
        this.dateTimeSelectionLabel = this.filters.dateTimeSelectionLabel;
      }

      this.loadData();
    }
  }
};
