<template>
  <v-dialog v-model="display" :width="dialogWidth" :height="500">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        v-on="on"
        readonly
      >
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              @input="showTimePicker"
              full-width
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="time"
              v-bind="timePickerProps"
              full-width
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey lighten-1" text @click.native="clearHandler">{{
            clearText
          }}</v-btn>
          <v-btn color="green darken-1" text @click="okHandler">{{
            okText
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.v-date-picker-title {
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>

<script>
const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";

import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  TIME_FORMAT
} from "../../core/constants/time";
import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "v-datetime-picker",
  model: {
    prop: "datetime",
    event: "input"
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ""
    },
    dialogWidth: {
      type: Number,
      default: 340
    },
    timeFormat: {
      type: String,
      default: "HH:mm"
    },
    clearText: {
      type: String,
      default: "Clear"
    },
    okText: {
      type: String,
      default: "Ok"
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    }
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    formattedDatetime() {
      return dayjs(this.selectedDatetime).format(DATETIME_FORMAT.SECOND);
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        if (this.time.length === 5) {
          datetimeString += ":00";
        }
        return dayjs(datetimeString, DATETIME_FORMAT.SECOND);
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    }
  },
  methods: {
    init() {
      if (!this.datetime) {
        return;
      }

      let initDateTime = dayjs(this.datetime, DATETIME_FORMAT.SECOND);
      this.date = dayjs(initDateTime, DATETIME_FORMAT.SECOND).format(
        DATE_FORMAT.DAY
      );
      this.time = dayjs(initDateTime, DATETIME_FORMAT.SECOND).format(
        TIME_FORMAT.SECOND
      );
    },
    okHandler() {
      this.resetPicker();
      this.$emit(
        "input",
        dayjs(this.selectedDatetime, DATETIME_FORMAT.SECOND).format(
          DATETIME_FORMAT.SECOND
        )
      );
    },
    clearHandler() {
      this.resetPicker();
      this.date = dayjs().format(DATE_FORMAT.DAY);
      this.time = DEFAULT_TIME;
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    }
  }
};
</script>
