import gql from "graphql-tag";

const NRQL_QUERY_NAGIOS = gql`
  query(
    $account_id: Int!
    $filters: [String]!
    $samples: [String]!
    $fields: [String]!
    $action: String!
  ) {
    nrqlQuery(
      account_id: $account_id
      filters: $filters
      instance: SNOW_BELGIUM
      samples: $samples
      fields: $fields
      action: $action
    ) {
      customer_hostname
      sentia_fqdn
      sentia_nrpe_plugin
      sentia_nrpe_short_name
      serviceCheck_command
      serviceCheck_message
      serviceCheck_status
    }
  }
`;

const NRQL_QUERY_INSIGHTS = gql`
  query(
    $account_id: Int!
    $filters: [String]!
    $samples: [String]!
    $fields: [String]!
    $action: String!
    $date_from: String
  ) {
    nrqlQuery(
      account_id: $account_id
      filters: $filters
      instance: SNOW_BELGIUM
      samples: $samples
      fields: $fields
      action: $action
      date_from: $date_from
    ) {
      beginTimeSeconds
      endTimeSeconds
      cpu
      memory
    }
  }
`;

const NEWRELIC_DAHSBOARD = gql`
  query($name: String!) {
    get_new_relic_dashboard_incident_links(name: $name) {
      url
      name
    }
  }
`;

const NEWRELIC_NRQL = gql`
  query($account_id: Int!, $query: String!) {
    platforms {
      new_relic {
        nrql(account_id: $account_id, query: $query) {
          result {
            response
          }
        }
      }
    }
  }
`;

export {
  NRQL_QUERY_NAGIOS,
  NEWRELIC_DAHSBOARD,
  NRQL_QUERY_INSIGHTS,
  NEWRELIC_NRQL
};
