const SessionStorageService = {
  setItem(key, item) {
    window.sessionStorage.setItem(key, window.btoa(JSON.stringify(item)));
  },
  getItem(key) {
    const item = window.sessionStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(window.atob(item));
      } catch (e) {
        window.sessionStorage.removeItem(key);
      }
    }
    return null;
  },
  removeItem(key) {
    window.sessionStorage.removeItem(key);
  }
};

export default SessionStorageService;
