<template>
  <v-right-menu
    offset-y
    :close-on-content-click="false"
    v-model="menu"
    ref="rightMenuRef"
    :z-index="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        block
        color="grey darken-1"
        v-bind="attrs"
        v-on="on"
        outlined
        class="d-block flex-fill"
        :disabled="disabled"
      >
        <div
          class="d-flex flex-row justify-content-start align-items-center flex-fill"
        >
          <div class="d-flex flex-row align-items-center">
            <v-icon left>mdi-clock-outline</v-icon>
            <div v-if="currentSelectedPreset.customDate">
              <div class="text-right">
                From: <span class="text-success">{{ formattedStartDate }}</span>
              </div>
              <div class="text-right">
                To: <span class="text-success">{{ formattedEndDate }}</span>
              </div>
            </div>
            <div v-else>
              {{ currentSelectedPreset.label }}
            </div>
          </div>

          <div class="ml-auto">
            <v-icon right>mdi-menu-down </v-icon>
          </div>
        </div>
      </v-btn>
    </template>
    <v-card class="p-0">
      <v-card-text class="p-0">
        <div class="date-range">
          <div class="date-range__presets border-right" v-if="!noPresets">
            <v-list dense>
              <v-list-item-group v-model="selectedOptionIndex" color="success">
                <v-list-item
                  v-for="(preset, index) in presets"
                  :key="index"
                  @click="onPresetSelect(index)"
                >
                  <v-list-item-content class="py-0">
                    {{ preset.label }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="preset.customDate" class="m-0">
                    <v-icon v-if="preset.customDate" right dense
                      >mdi-chevron-down</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <transition name="slide-fade">
            <div
              v-if="presets[selectedOptionIndex].customDate"
              class="d-flex flex-column justify-content-between"
            >
              <div class="date-range__pickers py-2">
                <div class="date-range__picker date-range__pickers--start">
                  <v-datetime-picker
                    v-model="startDate"
                    label="Start time"
                    :key="formattedStartDate"
                  ></v-datetime-picker>
                  <div class="text-danger" v-if="!isStartTimeBeforeEndTime">
                    Start time must be before the End time
                  </div>
                </div>
                <div class="date-range__picker date-range__picker--end">
                  <v-datetime-picker
                    v-model="endDate"
                    label="End time"
                    :key="formattedEndDate"
                  ></v-datetime-picker>
                </div>
              </div>

              <div class="d-flex justify-content-end p-4">
                <v-btn color="success" text @click.native="reset" class="mr-2"
                  >Reset</v-btn
                >
                <v-btn color="dark" text @click.native="cancel" class="mr-2"
                  >Cancel</v-btn
                >
                <v-btn color="primary" @click.native="apply">Apply</v-btn>
              </div>
            </div>
          </transition>
        </div>
      </v-card-text>
    </v-card>
  </v-right-menu>
</template>

<script>
import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
import DatetimePicker from "./DatetimePicker";
import { DATETIME_FORMAT } from "../../core/constants/time";
import RightMenu from "./RightMenu";
import SessionStorageService from "../../core/services/sessionstorage.service";

export default {
  components: {
    VDatetimePicker: DatetimePicker,
    VRightMenu: RightMenu
  },
  props: {
    options: {
      type: Object,
      required: false
    },
    noPresets: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    presets: {
      type: Array,
      default: () => [
        { label: "5 minutes", interval: 5, unit: "minute" },
        { label: "30 minutes", interval: 30, unit: "minute" },
        { label: "1 hour", interval: 1, unit: "hour" },
        { label: "4 hours", interval: 4, unit: "hour" },
        { label: "1 day", interval: 1, unit: "day" },
        { label: "7 days", interval: 7, unit: "day" },
        { label: "1 month", interval: 1, unit: "month" },
        { label: "3 months", interval: 3, unit: "month" },
        { label: "6 months", interval: 6, unit: "month" }
      ]
    },
    useCustomRange: {
      type: Boolean,
      default: true
    },
    defaultItemIndex: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      menu: false,
      startDate: dayjs().subtract(7, "day").format(DATETIME_FORMAT.SECOND),
      endDate: dayjs().format(DATETIME_FORMAT.SECOND),
      currentSelectedPreset: {},
      selectedOptionIndex: this.defaultItemIndex
    };
  },
  computed: {
    formattedStartDate() {
      return dayjs(this.startDate).format(DATETIME_FORMAT.SECOND);
    },
    formattedEndDate() {
      return dayjs(this.endDate).format(DATETIME_FORMAT.SECOND);
    },
    isPresetActive() {
      return this.presets.map(
        preset =>
          preset.range[0] === this.startDate && preset.range[1] === this.endDate
      );
    },
    selectedPreset() {
      return this.presets[this.selectedOptionIndex];
    },
    isStartTimeBeforeEndTime() {
      return dayjs(this.startDate).isBefore(dayjs(this.endDate));
    }
  },
  watch: {
    menu: function (value) {
      if (!value) {
        this.setSelectedPresetIndex();
      }
    }
  },
  mounted() {
    if (this.useCustomRange) {
      this.presets.push({ label: "Custom range", customDate: true });
    }

    let itemFromSessionStorage = SessionStorageService.getItem(
      "DatetimeIntervalPicker"
    );

    this.currentSelectedPreset = this.presets[this.selectedOptionIndex];
    if (itemFromSessionStorage) {
      this.currentSelectedPreset = itemFromSessionStorage.selectedItem
        ? itemFromSessionStorage.selectedItem
        : this.currentSelectedPreset;
      this.startDate = itemFromSessionStorage.startDate;
      this.endDate = itemFromSessionStorage.endDate;
      this.setSelectedPresetIndex();
      this.onDateRangeChange();
    }
  },
  methods: {
    onPresetSelect(presetIndex) {
      this.selectedOptionIndex = presetIndex;
      let selectedItem = this.presets[presetIndex];
      this.endDate = dayjs().format(DATETIME_FORMAT.OFFSET_UTC);
      if (selectedItem.customDate) {
        this.startDate = dayjs()
          .subtract(7, "day")
          .format(DATETIME_FORMAT.OFFSET_UTC);
      } else {
        this.startDate = dayjs()
          .subtract(selectedItem.interval, selectedItem.unit)
          .format(DATETIME_FORMAT.OFFSET_UTC);
        this.onDateRangeChange();
      }
    },
    onDateRangeChange() {
      this.menu = false;
      this.currentSelectedPreset = this.presets[this.selectedOptionIndex];
      let itemToSave = {
        selectedItem: this.currentSelectedPreset,
        startDate: this.startDate,
        endDate: this.endDate
      };

      SessionStorageService.setItem("DatetimeIntervalPicker", itemToSave);
      let dateTimeSelectionLabel = !this.currentSelectedPreset.customDate
        ? `Since ${this.currentSelectedPreset.label} ago`
        : `Since ${this.formattedStartDate} until ${this.formattedEndDate}`;

      this.$emit("dateFilterChanged", {
        startDate: dayjs(this.startDate).format(DATETIME_FORMAT.OFFSET_UTC),
        endDate: dayjs(this.endDate).format(DATETIME_FORMAT.OFFSET_UTC),
        dateTimeSelectionLabel: dateTimeSelectionLabel
      });
    },
    cancel() {
      this.menu = false;
    },
    reset() {
      this.startDate = dayjs()
        .subtract(7, "day")
        .format(DATETIME_FORMAT.SECOND);
      this.endDate = dayjs().format(DATETIME_FORMAT.SECOND);
    },
    apply() {
      if (this.isStartTimeBeforeEndTime) {
        this.onDateRangeChange();
      }
    },
    setSelectedPresetIndex() {
      this.selectedOptionIndex = this.presets.findIndex(
        x => x.label === this.currentSelectedPreset.label
      );
    }
  }
};
</script>

<style scoped>
.date-range__picker {
  padding: 0 1rem;
}
.v-list-item {
  min-height: 25px !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.v-btn__content .v-icon.v-icon--left,
.v-btn__content .v-icon.v-icon--right {
  font-size: 24px;
  height: 24px;
  width: 24px;
}
</style>
