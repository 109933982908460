<template>
  <div>
    <div class="d-flex align-items-start justify-content-between flex-grow-1">
      <div class="mr-2">
        <h6 class="font-weight-bolder">
          {{ title }}
        </h6>
        <div class="text-muted font-size-lg mt-2">
          {{ dateTimeLabel }}
        </div>
      </div>
      <div class="spinner-border text-warning" role="status" v-if="isLoading">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <apexchart
      ref="demoChart"
      v-if="!isLoading"
      width="100%"
      height="500"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "PieGraph",
  props: {
    title: {
      type: String,
      default: "Chart"
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  data: function () {
    return {
      isLoading: true,
      dateTimeLabel: "",
      options: {
        plotOptions: {
          pie: {
            donut: {
              size: "65%"
            }
          }
        },
        chart: {
          id: "vuechart-performance",
          type: "donut",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          shared: false,
          marker: {
            show: true
          },
          x: {
            show: false
          }
        },
        legend: {
          formatter: function (seriesName, opts) {
            const spanSeriesName = `<span class="text-truncate d-inline-block" style="width: 150px">${seriesName}</span>`;
            const spanSeriesCount = `<span class="text-right d-inline-block" style="width: 50px">${(
              opts.w.globals.series[opts.seriesIndex] / 1000
            ).toFixed(2)} k</span>`;

            const totalValueOfSeries = opts.w.globals.series.sum();
            const spanSeriesPercentage = `<span class="text-right d-inline-block" style="width: 50px">${(
              (opts.w.globals.series[opts.seriesIndex] * 100) /
              totalValueOfSeries
            ).toFixed(2)} %</span>`;

            return [spanSeriesName, spanSeriesCount, spanSeriesPercentage];
          }
        },
        stroke: {
          width: 2,
          curve: "straight"
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        labels: []
      },
      series: []
    };
  },
  methods: {
    loadData(dateTimeLabel) {
      this.dateTimeLabel = dateTimeLabel;
      this.isLoading = true;
    },
    drawChart(data) {
      if (!data) {
        return;
      }

      let jsonData = JSON.parse(data);

      this.options.labels = jsonData.select(x => x.facet);
      this.series = jsonData.select(x => x.count);
      this.isLoading = false;
    }
  }
};
</script>
